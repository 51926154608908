@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Mono:wght@600&display=swap');
@import url('https://rsms.me/inter/inter.css');

@font-face {
  font-family: 'IBM Plex Mono';
  src: url('assets/fonts/IBMPlexMono-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
